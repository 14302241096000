<template>
    <div class="main-container"  dir="ltr">
        <div class="row" dir="rtl">
            <div class="col-xl-6 col-md-6">
                <div class="my-account-content2" dir="rtl">
                    <div class="header-profile-login">
                        <div class="row justify-content-between">
                            <div class="col-10 col-sm-10 col-lg-10 col-xl-10">
                                <h6 class="text-center">تسجيل دخول</h6>
                            </div>
                            <div class="col-2 col-sm-2 col-lg-2 col-xl-2" style="padding-right: 0;">
                                <img style="width: 45px;float: left;margin-top: -18px;margin-left: -15px;" class="logo" src="../../assets/images/logo.png" />
                            </div>
                        </div>
                        <form id="loginform" @submit.prevent="onLogin()">
                            <p class="login-username">
                                <label for="user_login" style="margin-top: 20px;margin-bottom: 20px;text-align: center;">رقم الهاتف</label>
                                <input type="text" v-model="msisdn" class="input" size="15" autocomplete="off" placeholder="رقم الهاتف" onfocus="this.placeholder = ''" onblur="this.placeholder = 'رقم الهاتف'">
                            </p>
                            <p class="login-submit">
                                <button class="button button-primary" style="width: 100%;background: #078CC5;">
                                    <span v-if="loading" class="px-1 arabickufi">جاري تسجيل الدخول</span> &nbsp;
                                    <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                                    <span v-else class="arabickufi">تسجيل الدخول</span>
                                </button>
                                <!-- <button type="submit" style="width: 100%;background: #078CC5;" name="wp-submit" id="wp-submit" class="button button-primary" value="إشتـــراك"></button> -->
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { HTTPSPA } from '@/Api/http-Spay';
import { useToast } from "vue-toastification";
export default {
name:'AppLogin',
data() {
    return {
        msisdn: "",
        loading: false,
    };
  },
  mounted() {
    if (this.$cookie.isCookieAvailable("msisdn") && this.$cookie.isCookieAvailable("status")) {
        this.$router.push({ path: "/" })
    }
  },
methods: {
    async onLogin() {
      const toast = useToast();
      if(this.msisdn.startsWith(9)) {
          toast.error("رقم الهاتف ليس سوداني", { timeout: 2500, });
      } else {
      if (this.msisdn != "") {
        const str = this.msisdn;
        if (str.substr(0, 1) === "0") {
          let number = str.substr(1);
          if (number.startsWith(1)) {
            this.phone = number;
          } else {
            toast.error("رقم الهاتف ليس سوداني", { timeout: 2500,});
          }
        } else if (str.substr(0, 1) === "2" && str.length === 12) {
          let number = str.substr(3);
          if (number.startsWith(1)) {
            this.phone = number;
          } else {
            toast.error("رقم الهاتف ليس سوداني", { timeout: 2500,});
          }
        } else if (str.substr(0, 1) === "1" && str.length === 9) {
          let number = str.substr();
          if (number.startsWith(1)) {
            this.phone = number;
          } else {
            toast.error("رقم الهاتف ليس سوداني", { timeout: 2500, });
          }
        } else if (str.length != 9) {
          toast.error("رقم الهاتف يجب ان يتكون من 10 ارقام", { timeout: 2500,});
        }
          if (this.phone.startsWith(1)) {
            this.loading = true;
            await HTTPSPA.post(`CheckLogin.php?username=249`+this.phone +`&serviceId=2222&operatorID=2`).then((res) => {
                if (res.data.status === 1 && res.data.MINTUES > 0) {
                    this.$cookie.setCookie("msisdn", "249" + this.phone, { expire: 60 * res.data.MINTUES, });
                    this.$cookie.setCookie("status", res.data.status, { expire: 60 * res.data.MINTUES,});
                    this.$cookie.setCookie("remming_minutes", res.data.MINTUES, { expire: 60 * res.data.MINTUES,});
                    toast.success("تم تسجيل الدخول استمتع بتجربة فريدة من منصة كورساتي", { timeout: 2500 });
                    setTimeout(() => this.$router.push({ path: "/" }), 2500);
                } else if (res.data.status === 1 && res.data.MINTUES < 0) {
                    toast.success("تم تسجيل الدخول", { timeout: 2500, });
                    this.$cookie.setCookie("msisdn", "249" + this.phone, { expire: 60 * 120, });
                    this.$cookie.setCookie("status", res.data.status, { expire: 60 * 120,});
                    this.$cookie.setCookie("remming_minutes", res.data.MINTUES, { expire: 60 * 120, });
                } else if (res.data.status === 0 && res.data.responseCode === 118) {
                    this.$cookie.setCookie("msisdn", "249" + this.phone, { expire: 60 * 120, });
                    toast.warning("عفوا انت مشترك مسبقا ولم يتم تجديد اشتراكك", { timeout: 2500, });
                    setTimeout(() => this.$router.push({ path: "/" }), 2500);
                }else if (res.data.status === 0) {
                    toast.warning("عفوا انت لست مشترك في منصة كورساتي", { timeout: 2500, });
                    window.open("http://sudanipay.com/subscription/courseatk/courseatk", "_self");
                    setTimeout(() => this.$router.push({ path: "/" }), 2500);
                }else {
                    toast.info("عفوا انت لست مشترك في منصة كورساتي" , { timeout: 2500 });
                    window.open("http://sudanipay.com/subscription/courseatk/courseatk", "_self");
                }
              }).catch((error) => {
                console.log(error);
              }).finally(() => {
                this.loading = false;
              });
          } else {
            toast.info("رقم الهاتف ليس سوداني", { timeout: 2500 });
          }
      } else {
        toast.info("الرجاء ادخال رقم الهاتف", { timeout: 2500 });
      }
      }      
    },
  },
}
</script>

<style>

</style>